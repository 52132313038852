import { socialLinks } from '~/constants/social-links';
import { PRODUCTION_SITE_URL } from '~/constants/service-urls';

const OG_TWITTER_SITE = socialLinks.find(
  ({ name }) => name === 'twitter'
)?.accountName;

export function getCommonMetaTags({
  path,
  ogType,
  ogImageUrl,
  title,
  description,
}) {
  const url = `${PRODUCTION_SITE_URL}${path}`;

  return [
    {
      hid: 'description',
      name: 'description',
      content: description,
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: url,
    },
    {
      hid: 'og:type',
      property: 'og:type',
      content: ogType,
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: 'DOXA',
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: title,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: ogImageUrl || `${PRODUCTION_SITE_URL}/default-og-image.png`,
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: description,
    },
    {
      hid: 'twitter:card',
      property: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      hid: 'twitter:site',
      property: 'twitter:site',
      content: OG_TWITTER_SITE,
    },
    {
      hid: 'twitter:title',
      property: 'twitter:title',
      content: title,
    },
    {
      hid: 'twitter:description',
      property: 'twitter:description',
      content: description,
    },
  ];
}
